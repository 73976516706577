export function sk_menu_click_handler(event) {
    if(document.querySelector('header[data-page="offer"]')) {
        return
    } else {
        if (document.body.scrollWidth < 820) {
            if (event.target.matches('#nav-mobile-button *') || event.target.matches('#nav-mobile-button')) {
                (document.body.classList.contains('menu-open')) ? document.body.classList.remove('menu-open') : document.body.classList.add('menu-open')
                if (document.body.classList.contains('wt-menu-open')) { document.body.classList.remove('wt-menu-open') }
            }
            if (event.target.matches('.nav_mobile_menu>.menu-item.menu-item-has-children>a')) {
                event.preventDefault()
                event.target.parentNode.querySelector('.sub-menu').classList.toggle('active')
            }
            if (event.target.matches('#sk-offer-mobile a') || event.target.matches('#sk-offer-mobile')) {
                event.preventDefault()
                document.body.classList.remove('menu-open')
                document.body.classList.toggle('wt-menu-open')
            }
            if (event.target.matches('#sk-offer')) {
                if (document.body.classList.contains('menu-open')) { document.body.classList.remove('menu-open') }
                (document.body.classList.contains('wt-menu-open')) ? document.body.classList.remove('wt-menu-open') : document.body.classList.add('wt-menu-open')
            }
        } else {
            // Detect a Menu Click on Menu Items with children
            if (event.target.matches('.menu_item_has_children *') || event.target.matches('.menu_item_has_children')) {
                event.preventDefault() // Prevent reload
    
                // Remove active state from the prevously selected menu
                if (document.querySelector('.nav_sub_menu .active')) {
                    document.querySelector('.nav_sub_menu .active').classList.remove('active')
                }
    
                if (document.body.classList.contains('wt-menu-open')) {
                    document.body.classList.remove('wt-menu-open')
                }
    
                // Set new sub-menu
                let skMenuParentID = (event.target.parentNode.id) ? event.target.hash : ''
    
                // Set classes
                document.body.classList.add('menu-open')
                document.querySelector(skMenuParentID + '.sub-menu').classList.add('active')
    
            } else if (event.target.matches('#sk-offer *') || event.target.matches('#sk-offer')) {
                event.preventDefault()
    
                if (document.querySelector('.nav_sub_menu .active')) {
                    document.querySelector('.nav_sub_menu .active').classList.remove('active')
                    document.body.classList.remove('menu-open')
                }
                document.body.classList.toggle('wt-menu-open')
    
            } else if (event.target.matches('#sk-desktop-menu-close *') || event.target.matches('#sk-desktop-menu-close')) {
                event.preventDefault()
                if (document.querySelector('.nav_sub_menu .active')) {
                    document.querySelector('.nav_sub_menu .active').classList.remove('active')
                }
            } else {
                document.body.classList.remove('menu-open')
                if (document.querySelector('.nav_sub_menu .active')) {
                    document.querySelector('.nav_sub_menu .active').classList.remove('active')
                }
            }
        }
    }
}

export function sk_menu_excerpt_on_hover() {
    if(document.querySelector('#sk-header .sk_header_infobox_content')) {
        const infobox = document.querySelector('#sk-header .sk_header_infobox_content')
        let defaultContent = infobox.innerHTML
    
        document.body.addEventListener('mouseover', (event) => {
            if (event.target.dataset.excerpt) {
                let title = event.target.innerText
                let excerpt = event.target.dataset.excerpt
                infobox.innerHTML = `<h2>${title}</h2><p>${excerpt}</p>`
            } else {
                infobox.innerHTML = defaultContent
            }
        })
    }
}