//imports
import { sk_menu_click_handler, sk_menu_excerpt_on_hover } from "./modules/menu.js"
// import { sk_wt_add_decription } from "./modules/wt.js"
import { sk_usps_height } from "./modules/usps.js"
import { sk_faq_height, sk_faq_toggle } from "./modules/faq.js"
import { sk_add_slider_navigation } from "./modules/slider.js"
import { WindowSizeTracker } from "./modules/tools.js"

//Define Variables



// Misc
const SKHeader = document.querySelector('#sk-header')
const SKFooter = document.querySelector('#sk-footer')

addEventListener("DOMContentLoaded", (event) => {

    document.body.setAttribute('style', '--sk-menu-height:' + SKHeader.clientHeight + 'px;--sk-footer-height:' + SKFooter.clientHeight + 'px;')

    // Shows Description of Menu Item in Info Box
    sk_menu_excerpt_on_hover()

    // Calculates Element Heights for USPs and FAQ Items
    sk_usps_height()
    sk_faq_height()

    // Initiate FAQ Toggler
    sk_faq_toggle()

    // Initiate Slider
    sk_add_slider_navigation()
}, { once: true });

// Mouse Event Handler
document.body.addEventListener('click', (e) => {

    // Controls classes on body element for Menu handling
    sk_menu_click_handler(e)
})

const resizeObserver = new WindowSizeTracker(({ width, height, isWidthChange, isHeightChange }) => {
    sk_usps_height()
    sk_faq_height()
    document.body.setAttribute('style', '--sk-menu-height:' + SKHeader.clientHeight + 'px;--sk-footer-height:' + SKFooter.clientHeight + 'px;')
})
