export function sk_usps_height() {
    let usps = []

    if(usps = Array.from(document.querySelectorAll('.sk_usps .sk_usp_wrapper'))) {
        usps.forEach(usp => {
            let text = (usp.querySelector('.sk_usp_text')) ? usp.querySelector('.sk_usp_text') : false
            let button = (usp.querySelector('.wp-block-buttons')) ? usp.querySelector('.wp-block-buttons') : false

            if(text) {
                text.setAttribute('style', '--sk-usp-text-height:' + text.scrollHeight + 'px')
            }

            if(button) {
                button.setAttribute('style', '--sk-usp-button-height:' + button.scrollHeight + 'px')
            }

            usp.setAttribute('role', 'region')
            usp.setAttribute('tabindex', '0')
        })
    }
}