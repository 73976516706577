export class WindowSizeTracker {
    constructor(callback, debounceTime = 250) {
        this.callback = callback;
        this.currentWidth = window.innerWidth;
        this.currentHeight = window.innerHeight;
        
        // Debounced resize handler
        this.handleResize = debounce(() => {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            
            if (newWidth !== this.currentWidth || newHeight !== this.currentHeight) {
                this.currentWidth = newWidth;
                this.currentHeight = newHeight;
                
                this.callback({
                    width: newWidth,
                    height: newHeight,
                    isWidthChange: newWidth !== this.currentWidth,
                    isHeightChange: newHeight !== this.currentHeight
                });
            }
        }, debounceTime);
        
        // Use ResizeObserver if available (more efficient)
        if (window.ResizeObserver) {
            this.resizeObserver = new ResizeObserver(this.handleResize);
            this.resizeObserver.observe(document.documentElement);
        } else {
            // Fallback to window.onresize
            window.addEventListener('resize', this.handleResize);
        }
    }
    
    // Cleanup method
    destroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        } else {
            window.removeEventListener('resize', this.handleResize);
        }
    }
}

// Debounce function to limit the rate at which the resize handler fires
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
