export function sk_faq_height() {
    let faqs = []

    if(faqs = Array.from(document.querySelectorAll('.sk_faq_content_wrapper .sk_faq_item_toggle'))) {
        faqs.forEach(toggle => {
            toggle.setAttribute('style', '--sk-faq-toggle-height:' + toggle.scrollHeight + 'px')
            toggle.parentElement.setAttribute('role', 'region')
            toggle.parentElement.setAttribute('tabindex', '0')
            
        })
    }
}

export function sk_faq_toggle() {
    const faqItems = Array.from(document.querySelectorAll('.sk_faq .sk_faq_item'))

    faqItems.forEach( (item, i, arr) => {
        item.addEventListener('click', (e) => {
            // Check if open to allow self closing
            let istoggled = item.classList.contains('open') ? true : false
            // Remove the open on all others
            arr.forEach(el => {
                el.classList.remove('open')
                if(el == document.activeElement) {
                    el.blur()
                }
            })
            // Open FAQ if not previously opened
            if(!istoggled) {
                item.classList.add('open')
            }
        })
    })
}